<template>
  <div class="history-page">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap">
        <div class="switch-btn">
          <div class="btn" :class="{'selected': !isHistory}" @click="isHistory = false">Табель</div>
          <div class="btn" :class="{'selected': isHistory}" @click="isHistory = true">История</div>
        </div>

        <div v-show="!isHistory" class="calendar">
          <div class="calendar-head">
            <div class="left-arrow" @click="decrease">
              <n-icon icon="arrow-left" />
            </div>
            <div class="title">{{ months[month] }} {{ year }}</div>
            <div class="right-arrow" @click="increase">
              <n-icon icon="arrow-right" />
            </div>
          </div>
          <div class="calendar-body">
            <div class="days">
              <div v-for="(weekDay, index) in defDays" class="wrap-day">
                <div class="day">{{ weekDay }}</div>
              </div>
            </div>
            <div class="dates">
              <div v-for="(week, index) in calendar()" :key="index" class="date-row">
                <div class="date-wrap">
                  <div class="date" :style="'background-color:'+getColor(workDays[week[1]?.numY]?.type)+';'">
                    {{ week[1]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :style="'background-color:'+getColor(workDays[week[2]?.numY]?.type)+';'">
                    {{ week[2]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :style="'background-color:'+getColor(workDays[week[3]?.numY]?.type)+';'">
                    {{ week[3]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :style="'background-color:'+getColor(workDays[week[4]?.numY]?.type)+';'">
                    {{ week[4]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :style="'background-color:'+getColor(workDays[week[5]?.numY]?.type)+';'">
                    {{ week[5]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :style="'background-color:'+getColor(workDays[week[6]?.numY]?.type)+';'">
                    {{ week[6]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :style="'background-color:'+getColor(workDays[week[0]?.numY]?.type)+';'">
                    {{ week[0]?.index }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="calendar-footer">
            <ul>
              <li v-for="(item, index) in visitTypes" v-show="index <= itemCount" :key="index" :class="item.class">
                <span class="circle" :style="'background-color:'+item.color+';'" />
                {{ item.title }}
              </li>
            </ul>
            <div class="button-like" @click="showMore">{{ itemCount > 5 ? 'Свернуть' : 'Развернуть' }}</div>
          </div>
        </div>

        <div v-show="isHistory">
          <div v-if="visits.length !== 0">
            <div v-for="item in visits" :key="item.id" class="item" :class="item.type">
              <div class="date">Дата: <span>{{ $app.date.format(item.createdAt, 'date') }}</span></div>
              <div class="date">Тип: <span>{{ types[item.type] }}</span></div>
              <div class="time">Время: <span>{{ $app.date.format(item.createdAt, 'time') }}</span></div>
            </div>
          </div>
          <div v-else class="empty" style="display: none;">
            Тут пока ничего нет
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/logo.png'

export default {
  name: 'Index',
  data() {
    return {
      logo,
      visitTypes: [
        { title: 'Праздник', value: 'celebrate', color: '#FFAF36', },
        { title: 'Опоздал', value: 'late', color: '#A70F61', },
        { title: 'Присутствовал', value: 'was', color: '#64C038', },
        { title: 'Пропуск', value: 'wasnt', color: '#FF0000', },
        { title: 'Выходной день', value: 'weekend', color: '#CFCECE', },
        { title: 'Рабочий день', value: '', color: '#ffffff', },
        { title: 'Нетрудоспособность (болезнь, карантин и т.д)', value: 'sickLeave', color: '#3AE2CE', },
        { title: 'Трудовой отпуск', value: 'laborLeave', color: '#F1CB04', },
        { title: 'Командировка', value: 'businessTrip', color: '#7C1DC9', },
        { title: 'Отпуск без сохранение заработной платы', value: 'freeVacation', color: '#FAFF00', },
        { title: 'Командировка выходного дня', value: 'weekendBusinessTrip', color: '#9F79C8', },
        { title: 'Отпуски в связи с родами (без сох. з/п)', value: 'maternityLeave', color: '#FF008A', },
        { title: 'Расторжение ТД', value: 'terminationTD', color: '#606060', },
        { title: 'Дополнительный отпуск', value: 'additionalVac', color: '#BD5306', },
      ],
      isHistory: false,
      types: {
        leave: 'Уход',
        enter: 'Приход',
      },
      itemCount: 5,
      visits: [],
      date: new Date(),
      cDate: new Date(),
      month: null,
      workDays: $app.auth.user().workDays,
      year: new Date().getFullYear(),
      days: [],
      defDays: [],
      rWeeks: [],
      rDays: [],
      months: [],
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
      },
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
      },
    }
  },
  watch: {
    year() {
      this.load()
    },
  },
  created() {
    this.load()
    this.init()
  },
  methods: {
    showMore() {
      this.itemCount = this.itemCount > 5 ? 5 : 99
    },
    load() {
      this.$var('load', true)
      if (navigator.onLine && $app.store.getter('app.buffer').length > 0) {
        $app.store.action('app.sendBuffer').finally(() => {
          $api.visits.get().then((response) => {
            this.visits = response.data.content
          }).finally(() => {
            $app.store.action('app.updateUser', { year: this.year, }).finally(() => {
              this.workDays = $app.auth.user().workDays
              this.$var('load', false)
            })
          })
        })
      } else {
        $api.visits.get().then((response) => {
          this.visits = response.data.content
        }).finally(() => {
          $app.store.action('app.updateUser', { year: this.year, }).finally(() => {
            this.workDays = $app.auth.user().workDays
            this.$var('load', false)
          })
        })
      }
    },
    init() {
      const lang = $app.store.getter('app.lang') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.defDays = this.localeDay[lang]
      this.cDate = this.date
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.updateView()
    },
    updateView() {
      this.dateForView = (this.cDate.getDate() <= 9 ? '0' + this.cDate.getDate() : this.cDate.getDate()) +
          ' ' + this.months[this.month].substr(0, 3) + '. ' + this.year
    },
    getColor(value) {
      const type = $n.filter(this.visitTypes, (e) => {
        if (e.value === value) {
          return e
        }
      })
      return type[0]?.color || '#ffffff'
    },
    getNumberOfDay(today) {
      const startOfYear = new Date(today.getFullYear(), 0, 0)
      const diff = today - startOfYear

      const oneDay = 1000 * 60 * 60 * 24
      return Math.floor(diff / oneDay)
    },
    calendar: function() {
      let weeks = {}
      let week = 0
      let days = {}
      const dlast = new Date(this.year, this.month + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        days = {
          ...days,
          [new Date(this.year, this.month, i).getDay()]: {
            index: i,
            numY: Number(this.getNumberOfDay(new Date(this.year, this.month, i))),
          },
        }
        if (new Date(this.year, this.month, i).getDay() === 0) {
          weeks = {
            ...weeks,
            [week]: days,
          }
          week += 1
          days = {}
        }
      }
      if (Object.keys(days).length !== 0) {
        weeks = {
          ...weeks,
          [week]: days,
        }
      }

      return weeks
    },
    decrease() {
      this.month--
      if (this.month < 0) {
        this.month = 12
        this.month--
        this.year--
      }
    },
    increase() {
      this.month++
      if (this.month > 11) {
        this.month = -1
        this.month++
        this.year++
      }
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  padding: 55px 15px 0 15px;
}

.history-page {
  padding-top: 50px;

  .wrap-title {
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    right: 0;
    top: calc(env(safe-area-inset-top) + 100px);
  }

  .empty {
    text-align: center;
    font-size: 1.5em;
    color: #ababab;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    color: #000000;
    font-size: 16px;
  }

  .wrap {
    .item {
      height: 50px;
      background: #F5F5FA;
      border-radius: 16px;
      border: 1px solid rgba(100, 192, 56, 0.5);
      box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5),
      5px 5px 10px rgba(170, 170, 204, 0.25), 10px 10px 20px rgba(170, 170, 204, 0.5), -10px -10px 20px #FFFFFF;
      margin-bottom: 15px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 12px;
      color: #000;

      &.enter {
        border: 1px solid #64C038;
      }

      &.leave {
        border: 1px solid #FF4B33;
      }

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-left: 10px;
      }
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #e1e0e0;
    flex-shrink: 0;
    margin-right: 4px;
  }

  .switch-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .btn {
      border: 1px solid #8A8888;
      box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(170, 170, 204, 0.25), 10px 10px 20px rgba(170, 170, 204, 0.5);
      border-radius: 16px;
      font-weight: 700;
      font-size: 16px;
      color: #000;
      width: 48%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s ease-in-out;

      &.selected {
        color: #fff;
        box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(170, 170, 204, 0.25), 10px 10px 20px rgba(170, 170, 204, 0.5), -10px -10px 20px #FFFFFF;
      }
    }
  }

  .calendar {
    &-head {
      background: var(--primary);
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px 10px 0 0;

      .title {
        font-weight: 700;
        font-size: 16px;
        color: #FFF;
        margin: 0 28px;
      }
    }

    &-body {
      padding: 20px 0;
      background: #FFF;

      .days {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        .wrap-day {
          padding: 0 4px;
        }

        .day {
          font-weight: 700;
          font-size: 16px;
          color: #000;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .dates {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 7px;

        .date-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .date-wrap {
          padding: 10px 4px;

          .date {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 16px;
            color: #000;

            &.inactive {
              color: #777676;
            }
          }
        }
      }
    }

    &-footer {
      padding: 16px 22px 19px;
      border-top: 1px solid #000;
      background: #FFF;
      border-radius: 0 0 10px 10px;

      ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-bottom: 15px;


        li {
          list-style: none;
          font-weight: 400;
          font-size: 12px;
          color: #000;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          width: 50%;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
